/*修改滚动条样式*/
div::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

div::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}

div::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb:hover {
  background: #999;
}

div::-webkit-scrollbar-corner {
  background: #179a16;
}
