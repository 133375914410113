@import "./iconfont/iconfont";
@import "./scrollBar";
@import "./vars";
//@import "./global";

html {
  --antd-wave-shadow-color: #E3481F;
}

.normal_page_container {
  width: 100%;

  .normal_page_full {
    width: 100%;
  }

  .normal_page_content {
    width: $--page-content-width;
    margin: 0 auto;
  }
}


//单行文字
.one-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//两行文字
.two-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.btn {
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: $--color-text-grey;

}

//线框按钮
.btn-line,
.btn-line:hover,
.btn-line:focus {
  @extend .btn;
  border: 1px solid $--color-theme;
  //border-image: $--theme-gradient;
  color: $--color-theme;
  height: 32px;
}

.btn-line:hover {
  opacity: .7;
}


//实色按钮
.btn-background {
  @extend .btn;
  //border: 1px solid $--color-theme;
  //border-color: ;
  // background-image: $--theme-gradient;
  color: $--color-white;
  height: 32px;
  width: 100px;
}

//无线框按钮
.table_action_btn {
  color: $--color-theme;
  user-select: none;
  cursor: pointer;
}


.input_wrapper {
  position: relative;

  .input_tip {
    position: absolute;
    left: 5px;
    bottom: -10px;
    line-height: 14px;
    width: 200px;
    color: $--color-error;
  }
}

//必填项before的红星
.required_title_before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

//flex布局绝对居中
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

//flex布局居左
.flex-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

//flex布局居右
.flex-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.flex-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.iconfont_text {
  font-family: 'iconfont' !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}


.footer_copyright {
  font-size: 14px;
  color: $--color-white;
}